<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>财务台账</b></span>
            <!--<div class="flex-center"><i class="iconfont iconjiantou-copy-copy"></i>返回</div>-->
        </div>
        <div class="project-info">
            <div class="project-condition">
                <div class="flex-center">
                    <div class="send-condition-item">
                        <el-select v-model="state" placeholder="搜索字段">
                            <el-option
                                    v-for="item in stateOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <el-input
                            v-if="state <12"
                            v-model="search_content"
                            placeholder="请输入搜索字段关键字"
                    ></el-input>
                    <el-date-picker
                            v-else
                            v-model="start_end_time"
                            type="daterange"
                            value-format="timestamp"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            class="margin-left"
                    >
                    </el-date-picker>
                    <el-select class="margin-left" v-model="sid" placeholder="请选择所属学会" clearable v-if="financiaBook">
                        <el-option
                                v-for="item in societyOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                    <el-button class="search" type="primary" @click="search">搜索</el-button>
                    <el-button class="margin-left" type="primary" @click="downFile()">导出</el-button>

                </div>
            </div>

            <div class="financial">
                <el-table
                        ref="financial_table"
                        v-loading="loading"
                        :data="tableData"
                        header-row-class-name="table-header"
                        style="width: 100%"
                        height="800px"
                        border
                        :cell-class-name="tableRowClassName"
                        :span-method="objectSpanMethod"
                >
                    <el-table-column fixed label="执行归档情况" align="center" width="110">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.pro_no}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed label="剩余会议基金合计" align="center" width="110">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.s_amount | format }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed label="会议名称" align="center" width="140">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed label="会议日期" align="center" width="120">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.time }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed label="医院负责人" align="center" width="100">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.chairman }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed label="学会负责人" align="center" width="100">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.user_name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="进项/销项编号" align="center" width="140">
                        <template slot-scope="scope">
                            <div class="table-item" v-html="scope.row.shenpi_no">
                                <!--{{  }}-->
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="上游赞助商" align="center" width="110">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.corporate_name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="联系人" align="center" width="80">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.contacts }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="招商函日期" align="center" width="105">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.invitation_letter_date }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="签合同日期" align="center" width="140">
                        <template slot-scope="scope">
                            <div class="table-item" v-if="scope.row.queren_date">
                                {{ scope.row.queren_date }} ({{scope.row.is_replied === 1 ? '已回' : '未回'}})
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="合同金额" align="center" width="100">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.amount | format }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="学会服务费" align="center" width="110">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.agreement_run_amount | format }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="可用会议基金" align="center" width="120">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.meet_run | format }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="到账金额" align="center" width="90">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.daokuan_amount | format }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="到账日期" align="center" width="100">
                        <template slot-scope="scope">
                            <div class="table-item">
                                <div v-for="(item,index) in scope.row.received_date" :key="index">
                                    {{ item.received_date }}({{item.received_money  | format }})
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="开票金额" align="center" width="90">
                        <template slot-scope="scope">
                            <div class="table-item" v-if="scope.row.fapiao_amount || scope.row.fapiao_amount ===0">
                                {{ scope.row.fapiao_amount | format }}
                            </div>
                            <div class="table-item" v-else>
                                --
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="票号" align="center" width="130">
                        <template slot-scope="scope">
                           <div  class="table-item">
                               <div v-if="scope.row.ticket_number">
                                   <div v-for="(item,index) in scope.row.ticket_number" :key="index">
                                       {{ item.invoice_type | invoice_type }} - {{ item.ticket_number }}({{item.fapiao_money | format}})
                                   </div>
                                   <el-button
                                           type="text"
                                           size="small"
                                           @click="showTicket(scope.row.s_id,scope.row.p_id,scope.row.x_id,scope.row.ticket_number)"
                                           v-if="scope.row.s_id"
                                   >编辑
                                   </el-button>
                               </div>
                           </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="开票日期" align="center" width="100">
                        <template slot-scope="scope">
                            <div class="table-item">
                                <div v-for="(item,index) in scope.row.ticket_number" :key="index">
                                    {{ item.received_date }}
                                </div>

                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="下游公司名称" align="center" width="140">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.d_corporate_name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="费用内容" align="center" width="110">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.payment }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="签合同日期" align="center" width="110">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.contract_time }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="实际合同金额" align="center" width="120">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.contract_amount | format }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="付款金额" align="center" width="90">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.payment_amount | format }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="付款日期" align="center" width="120">
                        <template slot-scope="scope">
                            <div class="table-item">
                                <div v-for="(item,index) in scope.row.received" :key="index">
                                    {{ item.received_date }}({{item.received_money | format}})
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="收票金额" align="center" width="110">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.collection_amount | format }}
                                <el-button
                                        type="text"
                                        size="small"
                                        @click="showMoney(scope.row.d_id)"
                                        v-if="scope.row.d_id"
                                >编辑
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="备注" align="center" width="150">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.desc_info }}
                                <el-button
                                        type="text"
                                        size="small"
                                        @click="showRemark(scope.row.d_id)"
                                        v-if="scope.row.d_id"
                                >编辑
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>

                </el-table>
            </div>
        </div>
        <el-dialog
                :visible="editMoney"
                :close-on-click-modal="false"
                width="500px"
                center
                :before-close="closeDialog"
        >
            <span slot="title" class="dialog-title">设置发票金额（万元）</span>
            <div class="editFlForm">
                <el-input
                        v-model="remark"
                        type="number"
                        @mousewheel.native.prevent
                        placeholder="发票金额"
                >
                </el-input>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editHandWriting">确定</el-button>
        <el-button @click="closeDialog">取消</el-button>
      </span>
        </el-dialog>
        <el-dialog
                :visible="editRemark"
                :close-on-click-modal="false"
                width="500px"
                center
                :before-close="closeDialog"
        >
            <span slot="title" class="dialog-title">备注</span>
            <div class="editFlForm">
                <el-input
                        v-model="remark"
                        placeholder="备注"
                >
                </el-input>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editHandWritingDesc">确定</el-button>
        <el-button @click="closeDialog">取消</el-button>
      </span>
        </el-dialog>
        <el-dialog
                :visible="editTickets"
                :close-on-click-modal="false"
                center
                :before-close="closeDialog"
        >
            <span slot="title" class="dialog-title">票号</span>
            <el-form :model="ruleForm" ref="ruleForm"  class="ticket-container">
                <div  class="ticket-item" v-for="(tickets,index) in ruleForm.cur_tickets" :key="index">
                   <div class="flex-center">
                       <el-form-item
                               label="发票类型"
                               :prop="`cur_tickets.${index}.invoice_type`"
                               :rules="{required: true, message: '请选择发票类型', trigger: 'blur' }">
                           <el-select
                                   v-model="tickets.invoice_type"
                                   placeholder="类型">
                               <el-option
                                       v-for="item in typeOptions"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value"
                               >
                               </el-option>
                           </el-select>
                       </el-form-item>
                       <el-form-item
                               class="margin-left"
                               label="票号"
                               :prop="`cur_tickets.${index}.ticket_number`"
                               :rules="{required: true, message: '票号不能为空', trigger: 'blur' }">
                           <el-input v-model="tickets.ticket_number"></el-input>
                       </el-form-item>
                       <el-form-item
                               class="margin-left"
                               label="发票金额"
                               :prop="`cur_tickets.${index}.fapiao_money`"
                               :rules="{required: true, message: '发票金额不能为空', trigger: 'blur' }">
                           <el-input v-model="tickets.fapiao_money"></el-input>
                       </el-form-item>
                       <el-form-item
                               class="margin-left"
                               label="开票日期"
                               :prop="`cur_tickets.${index}.received_date`"
                               :rules="{required: true, message: '开票日期不能为空', trigger: 'blur' }">
                           <el-date-picker
                                   v-model="tickets.received_date"
                                   type="date"
                                   format="yyyy-MM-dd"
                                   value-format="yyyy-MM-dd">
                           </el-date-picker>
                       </el-form-item>
                   </div>
                    <el-button @click.prevent="removeTickets(index)">删除</el-button>
                </div>
                <el-button @click="addTickets" type="text">+添加</el-button>
                <el-form-item style="align-self: center; margin-top: 30px" label-width="0px">
                    <el-button type="primary" @click="submitInvoice('ruleForm')">提交</el-button>
                    <el-button @click="closeDialog">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import ElInput from "../../../node_modules/element-ui/packages/input/src/input.vue";

    export default {
        components: {ElInput},
        name: "list",
        data() {
            return {
                state: 0,
                stateOptions: [
                    {
                        value: 0,
                        label: "全部",
                    },
                    {
                        value: 1,
                        label: "项目编号",
                    },
                    {
                        value: 2,
                        label: "项目名称",
                    },
                    {
                        value: 3,
                        label: "大会主席",
                    },
                    {
                        value: 4,
                        label: "学会负责人",
                    },
                    {
                        value: 5,
                        label: "上游赞助商",
                    },
                    {
                        value: 6,
                        label: "上游联系人",
                    },
                    {
                        value: 7,
                        label: "下游公司名称",
                    },
                    {
                        value: 8,
                        label: "下游费用内容",
                    },
                    {
                        value: 9,
                        label: "下游备注",
                    },
                    {
                        value: 10,
                        label: "进项编号",
                    },
                    {
                        value: 11,
                        label: "销项编号",
                    },
                    {
                        value: 12,
                        label: "招商函日期",
                    },
                    {
                        value: 13,
                        label: "上游签合同日期",
                    },
//                    {
//                        value: 14,
//                        label: "到账日期",
//                    },
//                    {
//                        value: 15,
//                        label: "开票日期",
//                    },
                    {
                        value: 16,
                        label: "下游签合同日期",
                    },
                    {
                        value: 17,
                        label: "付款日期",
                    },
                    {
                        value: 18,
                        label: "会议日期",
                    },
                ],
                start_end_time: [],
                search_content: "",
                tableData: [],
                column_1: [],
                pos_1: 0,
                column_2: [],
                pos_2: 0,
                column_3: [],
                pos_3: 0,
                loading: false,
                editMoney: false,
                editRemark: false,
                ruleForm:{ cur_tickets : []},
                typeOptions: [{value: 1, label: "专",}, {value: 2, label: "普",}, {value: 3, label: "捐",}],
                editTickets: false,
                remark: '',
                cur_id: -1,
                s_id: '',
                p_id: '',
                societyOptions: [],       //  学会
                sid: '',
                financiaBook : this.$store.state.user.jid ===2  || this.$store.state.user.jid ===3,

                oid:0
            };
        },
        created() {
            this.$emit("setIndex", [1, 2]);
            this.oid = Number(this.$route.query.oid || 0)
            if (this.oid) this.noticeUnreadClearAll()
            this.init();
        },
        mounted() {
            // 绑定window的onresize事件（注意，onresize事件只能有一个）
            window.onresize = () => {
                // myTable是表格的ref属性值
                this.upTableStyle()
            }
        },
        methods: {
            upTableStyle(){
                // myTable是表格的ref属性值
                if (this.$refs.financial_table && this.$refs.financial_table.doLayout) {
                    this.$refs.financial_table.doLayout()
                }
            },
            noticeUnreadClearAll(){
                let that = this
                that.$api.apiContent.noticeUnreadClearAll({
                        token: that.$store.state.user.token,
                        timestamp: that.$tools.requestTime(),
                        pid: this.oid,
                        sign: that.$tools.SHA256(
                            {
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                pid: this.oid,
                            },
                            that.$store.state.user.key
                        ),
                    })
            },
            search() {
                this.page = 1
                this.init();
            },
            timeChange() {
                this.page = 1
                this.init();
            },
            // 分页变化
            setPage(val) {
                this.page = val;
                this.init();
            },
            showTicket(sid,pid,xid,tickets) {
                this.cur_id = xid
                this.s_id = sid
                this.p_id = pid
                tickets.map(item=>{
                    item.fapiao_money = item.fapiao_money ? parseFloat(Number(item.fapiao_money).toFixed(6)):''
                })
                this.ruleForm = {cur_tickets : JSON.parse(JSON.stringify(tickets))}
                this.editTickets = true
            },
            addTickets(){
              this.ruleForm.cur_tickets.push({ticket_number:'',invoice_type:1,fapiao_money:'',received_date:''})
            },
            removeTickets(index){
                this.ruleForm.cur_tickets.splice(index, 1)
            },
            submitInvoice(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.setInvoice()
                    } else {
                        return false;
                    }
                });
            },
            setInvoice(){
                let that = this
                that.$api.apiContent
                    .setInvoice({
                        token: that.$store.state.user.token,
                        timestamp: that.$tools.requestTime(),
                        zid: this.cur_id,
                        sid: this.s_id,
                        pid: this.p_id,
                        data: JSON.stringify(that.ruleForm.cur_tickets),
                        sign: that.$tools.SHA256(
                            {
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                zid: this.cur_id,
                                sid: this.s_id,
                                pid: this.p_id,
                                data: JSON.stringify(that.ruleForm.cur_tickets),
                            },
                            that.$store.state.user.key
                        ),
                    })
                    .then(() => {
                        that.closeDialog();
                        that.init();
                    })
                    .catch((e) => {
                        that.$message.error(e.msg);
                    });
            },
            showMoney(id) {
                this.cur_id = id
                this.remark = ''
                this.editMoney = true
            },
            showRemark(id) {
                this.cur_id = id
                this.remark = ''
                this.editRemark = true
            },
            //  关闭弹框
            closeDialog() {
                this.editMoney = false;
                this.editRemark = false;
                this.editTickets = false;
                this.cur_id = -1
                this.s_id = ''
                this.p_id = ''
                this.ruleForm = { cur_tickets : []}
            },
            editHandWriting() {
                let that = this;
                if (!this.remark) {
                    this.$message.error("请输入发票金额");
                    return;
                }
                that.$api.apiContent
                    .editHandWriting({
                        token: that.$store.state.user.token,
                        timestamp: that.$tools.requestTime(),
                        id: this.cur_id,
                        collection_amount: that.remark,
                        sign: that.$tools.SHA256(
                            {
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                id: this.cur_id,
                                collection_amount: that.remark,
                            },
                            that.$store.state.user.key
                        ),
                    })
                    .then(() => {
                        that.closeDialog();
                        that.init();
                    })
                    .catch((e) => {
                        that.$message.error(e.msg);
                    });
            },
            editHandWritingDesc() {
                let that = this;
                if (!this.remark) {
                    this.$message.error("请输入备注");
                    return;
                }
                that.$api.apiContent
                    .editHandWritingDesc({
                        token: that.$store.state.user.token,
                        timestamp: that.$tools.requestTime(),
                        id: this.cur_id,
                        desc_info: that.remark,
                        sign: that.$tools.SHA256(
                            {
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                id: this.cur_id,
                                desc_info: that.remark,
                            },
                            that.$store.state.user.key
                        ),
                    })
                    .then(() => {
                        that.closeDialog();
                        that.init();
                    })
                    .catch((e) => {
                        that.$message.error(e.msg);
                    });
            },
            getSpanArr(data) {
                for (var i = 0; i < data.length; i++) {
                    if (i === 0) {
                        this.column_1.push(1);
                        this.column_2.push(1);
                        this.column_3.push(1);
                        this.pos_1 = 0
                        this.pos_2 = 0
                        this.pos_3 = 0
                    } else {
                        // 判断当前元素与上一个元素是否相同
                        if (data[i].column_1 === data[i - 1].column_1) {
                            this.column_1[this.pos_1] += 1;
                            this.column_1.push(0);
                        } else {
                            this.column_1.push(1);
                            this.pos_1 = i;
                        }
                        if (data[i].column_2 === data[i - 1].column_2) {
                            this.column_2[this.pos_2] += 1;
                            this.column_2.push(0);
                        } else {
                            this.column_2.push(1);
                            this.pos_2 = i;
                        }
                        if (data[i].column_3 === data[i - 1].column_3) {
                            this.column_3[this.pos_3] += 1;
                            this.column_3.push(0);
                        } else {
                            this.column_3.push(1);
                            this.pos_3 = i;
                        }
                    }
                }
            },
            tableRowClassName({row, columnIndex}) {
                if (columnIndex !== 0) {
                    if (row.color === 0) {
                        return 'warning-row';
                    } else if (row.color === 1) {
                        return 'success-row';
                    }
                }
                if (row.color === 2) {
                    return 'error-row';
                }
                return '';
            },
            objectSpanMethod({rowIndex, columnIndex}) {
                if (columnIndex === 0) {
                    const _row = this.column_1[rowIndex];
                    const _col = _row > 0 ? 1 : 0;
                    return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
                        rowspan: _row,
                        colspan: _col
                    }
                } else if (columnIndex === 1) {
                    const _row = this.column_2[rowIndex];
                    const _col = _row > 0 ? 1 : 0;
                    return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
                        rowspan: _row,
                        colspan: _col
                    }
                } else if (columnIndex === 2 || columnIndex === 3 || columnIndex === 4 || columnIndex === 5) {
                    const _row = this.column_3[rowIndex];
                    const _col = _row > 0 ? 1 : 0;
                    return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
                        rowspan: _row,
                        colspan: _col
                    }
                }
            },
            //  页面加载时请求
            init() {
                this.loading = true
                // 获取学会列表
                this.$api.apiContent.getSociety({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                    }, this.$store.state.user.key)
                }).then(res => {
                    this.societyOptions = res.data
                }).catch(() => {
                    this.societyOptions = []
                })
                this.$api.apiContent
                    .getStandingBook({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        page: this.page,
                        limit: this.limit,
                        start_time:
                            this.start_end_time && this.start_end_time.length > 0
                                ? this.start_end_time[0] / 1000
                                : "",
                        end_time:
                            this.start_end_time && this.start_end_time.length > 0
                                ? ((this.start_end_time[1] / 1000) + (24 * 60 * 60))
                                : "",
                        state: this.state,
                        name: this.search_content,
                        sid: this.sid,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                page: this.page,
                                limit: this.limit,
                                start_time:
                                    this.start_end_time && this.start_end_time.length > 0
                                        ? this.start_end_time[0] / 1000
                                        : "",
                                end_time:
                                    this.start_end_time && this.start_end_time.length > 0
                                        ? ((this.start_end_time[1] / 1000) + (24 * 60 * 60))
                                        : "",
                                state: this.state,
                                name: this.search_content,
                                sid: this.sid,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        let _data = []
                        res.data.map((item, index) => {//项目
                            //专项
                            let type = item.breast.meet  //学会
                            for (var poistion = 0; poistion < Math.max(type.upper.data.length, type.downstream.data.length); poistion++) {
                                _data.push({
                                    column_1: index,
                                    column_2: '1-' + index,
                                    column_3: '1-' + index,
                                    color: item.id === this.oid ? 2 : 0,
                                    pro_no: item.pro_no.join(","),
                                    s_amount: type.upper.can_meet_amount - type.downstream.fukuan_amount,
                                    name: item.name,

                                    time: this.$tools.ZHDate(new Date(item.start_time * 1000)) +
                                    " 至 " + this.$tools.ZHDate(new Date(item.end_time * 1000)),
                                    chairman: item.chairman,
                                    user_name: item.user_name,
                                    shenpi_no: (type.upper.data.length > poistion ? type.upper.data[poistion].shenpi_no : '') + '</br>' + (type.downstream.data.length > poistion ? type.downstream.data[poistion].shenpi_no : ''),
                                    corporate_name: (type.upper.data.length > poistion ? type.upper.data[poistion].corporate_name : ''),
                                    s_id: (type.upper.data.length > poistion ? type.upper.data[poistion].sid : ''),
                                    p_id: (type.upper.data.length > poistion ? type.upper.data[poistion].pid : ''),
                                    x_id: (type.upper.data.length > poistion ? type.upper.data[poistion].xid : ''),
                                    contacts: (type.upper.data.length > poistion ? type.upper.data[poistion].contacts : ''),
                                    invitation_letter_date: (type.upper.data.length > poistion ? type.upper.data[poistion].invitation_letter_date : ''),
                                    queren_date: (type.upper.data.length > poistion ? type.upper.data[poistion].queren_date : ''),
                                    is_replied: (type.upper.data.length > poistion ? type.upper.data[poistion].is_replied : ''),
                                    //合同金额
                                    amount: (type.upper.data.length > poistion ? type.upper.data[poistion].meeting_decimal_zan : ''),
                                    //服务费
                                    agreement_run_amount: (type.upper.data.length > poistion ? type.upper.data[poistion].agreement_run_amount : ''),
//                                  //可用基金
                                    meet_run: (type.upper.data.length > poistion ? type.upper.data[poistion].meet_run : ''),
                                    //到账金额
                                    daokuan_amount: (type.upper.data.length > poistion ? type.upper.data[poistion].daokuan_amount_meet : ''),
                                    received_date: (type.upper.data.length > poistion ? type.upper.data[poistion].daokuan : ''),
                                    fapiao_amount: (type.upper.data.length > poistion ? type.upper.data[poistion].fapiao_amount : ''),

                                    invoice_type: (type.upper.data.length > poistion ? type.upper.data[poistion].invoice_type : ''),
                                    ticket_number: (type.upper.data.length > poistion ? type.upper.data[poistion].fapiao : ''),

                                    invoice_date: (type.upper.data.length > poistion ? type.upper.data[poistion].invoice_date : ''),

                                    d_id: (type.downstream.data.length > poistion ? type.downstream.data[poistion].id : ''),
                                    d_corporate_name: (type.downstream.data.length > poistion ? type.downstream.data[poistion].corporate_name : ''),
                                    payment: (type.downstream.data.length > poistion ? type.downstream.data[poistion].payment : ''),
                                    contract_time: (type.downstream.data.length > poistion ? type.downstream.data[poistion].contract_time : ''),
                                    contract_amount: (type.downstream.data.length > poistion ? type.downstream.data[poistion].contract_amount : ''),
                                    payment_amount: (type.downstream.data.length > poistion ? type.downstream.data[poistion].payment_amount : ''),
                                    received: (type.downstream.data.length > poistion ? type.downstream.data[poistion].received : ''),
                                    collection_amount: (type.downstream.data.length > poistion ? type.downstream.data[poistion].collection_amount : ''),
                                    desc_info: (type.downstream.data.length > poistion ? type.downstream.data[poistion].desc_info : '--'),
                                })
                            }

                            if (type.upper.data.length > 0 || type.downstream.data.length > 0) {
                                _data.push({
                                    column_1: index,
                                    column_2: '1-' + index,
                                    column_3: '0-' + index,
                                    color: item.id === this.oid ? 2 : 0,
                                    name: '可用会议基金合计',
                                    amount: type.upper.hetong_amount_meet,
                                    agreement_run_amount: type.upper.society_amount,
                                    meet_run: type.upper.can_meet_amount,
                                    daokuan_amount: type.upper.daokuan_amount_meet,
                                    fapiao_amount: type.upper.fapiao_amount,
                                    d_corporate_name: '已用会议基金合计',
                                    contract_amount: type.downstream.true_hetong_amount,
                                    payment_amount: type.downstream.fukuan_amount,
                                    collection_amount: type.downstream.shoupiao_amount,
                                })
                            }
                            type = item.breast.self  //学会
                            for (let poistion = 0; poistion < Math.max(type.upper.data.length, type.downstream.data.length); poistion++) {
                                _data.push({
                                    column_1: index,
                                    column_2: '2-' + index,
                                    column_3: '1-' + index,
                                    color: item.id === this.oid ? 2 : 1,
                                    pro_no: item.pro_no.join(","),
                                    s_amount: type.upper.can_self_amount - type.downstream.fukuan_amount,
                                    name: item.name,

                                    time: this.$tools.ZHDate(new Date(item.start_time * 1000)) +
                                    " 至 " + this.$tools.ZHDate(new Date(item.end_time * 1000)),
                                    chairman: item.chairman,
                                    user_name: item.user_name,
                                    shenpi_no: (type.upper.data.length > poistion ? type.upper.data[poistion].shenpi_no : '') + '</br>' + (type.downstream.data.length > poistion ? type.downstream.data[poistion].shenpi_no : ''),
                                    corporate_name: (type.upper.data.length > poistion ? type.upper.data[poistion].corporate_name : ''),
                                    s_id: (type.upper.data.length > poistion ? type.upper.data[poistion].sid : ''),
                                    p_id: (type.upper.data.length > poistion ? type.upper.data[poistion].pid : ''),
                                    x_id: (type.upper.data.length > poistion ? type.upper.data[poistion].xid : ''),
                                    contacts: (type.upper.data.length > poistion ? type.upper.data[poistion].contacts : ''),
                                    invitation_letter_date: (type.upper.data.length > poistion ? type.upper.data[poistion].invitation_letter_date : ''),
                                    queren_date: (type.upper.data.length > poistion ? type.upper.data[poistion].queren_date : ''),
                                    is_replied: (type.upper.data.length > poistion ? type.upper.data[poistion].is_replied : ''),
                                    //合同金额
                                    amount: (type.upper.data.length > poistion ? type.upper.data[poistion].self_amount_zan : ''),
                                    //服务费
                                    agreement_run_amount: (type.upper.data.length > poistion ? type.upper.data[poistion].agreement_run_amount_self : ''),
//                                  //可用基金
                                    meet_run: (type.upper.data.length > poistion ? type.upper.data[poistion].self_run : ''),
                                    //到账金额
                                    daokuan_amount: (type.upper.data.length > poistion ? type.upper.data[poistion].daokuan_amount_self : ''),
                                    received_date: (type.upper.data.length > poistion ? type.upper.data[poistion].daokuan : ''),
//                                    fapiao_amount: (type.upper.data.length > poistion ? type.upper.data[poistion].fapiao_amount : ''),
                                    fapiao_amount: '',
                                    invoice_type: (type.upper.data.length > poistion ? type.upper.data[poistion].invoice_type : ''),
                                    ticket_number: '',
                                    invoice_date: (type.upper.data.length > poistion ? type.upper.data[poistion].invoice_date : ''),

                                    d_id: (type.downstream.data.length > poistion ? type.downstream.data[poistion].id : ''),
                                    d_corporate_name: (type.downstream.data.length > poistion ? type.downstream.data[poistion].corporate_name : ''),
                                    payment: (type.downstream.data.length > poistion ? type.downstream.data[poistion].payment : ''),
                                    contract_time: (type.downstream.data.length > poistion ? type.downstream.data[poistion].contract_time : ''),
                                    contract_amount: (type.downstream.data.length > poistion ? type.downstream.data[poistion].contract_amount : ''),
                                    payment_amount: (type.downstream.data.length > poistion ? type.downstream.data[poistion].payment_amount : ''),
                                    received: (type.downstream.data.length > poistion ? type.downstream.data[poistion].received : ''),
                                    collection_amount: (type.downstream.data.length > poistion ? type.downstream.data[poistion].collection_amount : ''),
                                    desc_info: (type.downstream.data.length > poistion ? type.downstream.data[poistion].desc_info : '--'),
                                })
                            }
                            if (type.upper.data.length > 0 || type.downstream.data.length > 0) {
                                _data.push({
                                    column_1: index,
                                    column_2: '2-' + index,
                                    column_3: '0-' + index,
                                    color: item.id === this.oid ? 2 : 1,
                                    name: '可用专项基金合计',
                                    amount: type.upper.hetong_amount_self,
                                    agreement_run_amount: type.upper.society_amount_self,
                                    meet_run: type.upper.can_self_amount,
                                    daokuan_amount: type.upper.daokuan_amount_self,
                                    fapiao_amount: '',
                                    d_corporate_name: '已用专项基金合计',
                                    contract_amount: type.downstream.true_hetong_amount,
                                    payment_amount: type.downstream.fukuan_amount,
                                    collection_amount: type.downstream.shoupiao_amount,
                                })
                            }
                            //专项
                            type = item.cancer.meet  //学会
                            for (poistion = 0; poistion < Math.max(type.upper.data.length, type.downstream.data.length); poistion++) {
                                _data.push({
                                    column_1: index,
                                    column_2: '1-' + index,
                                    column_3: '1-' + index,
                                    color: item.id === this.oid ? 2 : 0,
                                    pro_no: item.pro_no.join(","),
                                    s_amount: type.upper.can_meet_amount - type.downstream.fukuan_amount,
                                    name: item.name,

                                    time: this.$tools.ZHDate(new Date(item.start_time * 1000)) +
                                    " 至 " + this.$tools.ZHDate(new Date(item.end_time * 1000)),
                                    chairman: item.chairman,
                                    user_name: item.user_name,
                                    shenpi_no: (type.upper.data.length > poistion ? type.upper.data[poistion].shenpi_no : '') + '</br>' + (type.downstream.data.length > poistion ? type.downstream.data[poistion].shenpi_no : ''),
                                    corporate_name: (type.upper.data.length > poistion ? type.upper.data[poistion].corporate_name : ''),
                                    s_id: (type.upper.data.length > poistion ? type.upper.data[poistion].sid : ''),
                                    p_id: (type.upper.data.length > poistion ? type.upper.data[poistion].pid : ''),
                                    x_id: (type.upper.data.length > poistion ? type.upper.data[poistion].xid : ''),
                                    contacts: (type.upper.data.length > poistion ? type.upper.data[poistion].contacts : ''),
                                    invitation_letter_date: (type.upper.data.length > poistion ? type.upper.data[poistion].invitation_letter_date : ''),
                                    queren_date: (type.upper.data.length > poistion ? type.upper.data[poistion].queren_date : ''),
                                    is_replied: (type.upper.data.length > poistion ? type.upper.data[poistion].is_replied : ''),
                                    //合同金额
                                    amount: (type.upper.data.length > poistion ? type.upper.data[poistion].meeting_decimal_zan : ''),
                                    //服务费
                                    agreement_run_amount: (type.upper.data.length > poistion ? type.upper.data[poistion].agreement_run_amount : ''),
//                                  //可用基金
                                    meet_run: (type.upper.data.length > poistion ? type.upper.data[poistion].meet_run : ''),
                                    //到账金额
                                    daokuan_amount: (type.upper.data.length > poistion ? type.upper.data[poistion].daokuan_amount_meet : ''),
                                    received_date: (type.upper.data.length > poistion ? type.upper.data[poistion].daokuan : ''),
                                    fapiao_amount: (type.upper.data.length > poistion ? type.upper.data[poistion].fapiao_amount : ''),
                                    invoice_type: (type.upper.data.length > poistion ? type.upper.data[poistion].invoice_type : ''),
                                    ticket_number: (type.upper.data.length > poistion ? type.upper.data[poistion].fapiao : ''),
                                    invoice_date: (type.upper.data.length > poistion ? type.upper.data[poistion].invoice_date : ''),

                                    d_id: (type.downstream.data.length > poistion ? type.downstream.data[poistion].id : ''),
                                    d_corporate_name: (type.downstream.data.length > poistion ? type.downstream.data[poistion].corporate_name : ''),
                                    payment: (type.downstream.data.length > poistion ? type.downstream.data[poistion].payment : ''),
                                    contract_time: (type.downstream.data.length > poistion ? type.downstream.data[poistion].contract_time : ''),
                                    contract_amount: (type.downstream.data.length > poistion ? type.downstream.data[poistion].contract_amount : ''),
                                    payment_amount: (type.downstream.data.length > poistion ? type.downstream.data[poistion].payment_amount : ''),
                                    received: (type.downstream.data.length > poistion ? type.downstream.data[poistion].received : ''),
                                    collection_amount: (type.downstream.data.length > poistion ? type.downstream.data[poistion].collection_amount : ''),
                                    desc_info: (type.downstream.data.length > poistion ? type.downstream.data[poistion].desc_info : '--'),
                                })
                            }
                            if (type.upper.data.length > 0 || type.downstream.data.length > 0) {
                                _data.push({
                                    column_1: index,
                                    column_2: '1-' + index,
                                    column_3: '0-' + index,
                                    color: item.id === this.oid ? 2 : 0,
                                    name: '可用会议基金合计',
                                    amount: type.upper.hetong_amount_meet,
                                    agreement_run_amount: type.upper.society_amount,
                                    meet_run: type.upper.can_meet_amount,
                                    daokuan_amount: type.upper.daokuan_amount_meet,
                                    fapiao_amount: type.upper.fapiao_amount,
                                    d_corporate_name: '已用会议基金合计',
                                    contract_amount: type.downstream.true_hetong_amount,
                                    payment_amount: type.downstream.fukuan_amount,
                                    collection_amount: type.downstream.shoupiao_amount,
                                })
                            }
                            type = item.cancer.self  //学会
                            for (poistion = 0; poistion < Math.max(type.upper.data.length, type.downstream.data.length); poistion++) {
                                _data.push({
                                    column_1: index,
                                    column_2: '2-' + index,
                                    column_3: '1-' + index,
                                    color: item.id === this.oid ? 2 : 1,
                                    pro_no: item.pro_no.join(","),
                                    s_amount: type.upper.can_self_amount - type.downstream.fukuan_amount,
                                    name: item.name,

                                    time: this.$tools.ZHDate(new Date(item.start_time * 1000)) +
                                    " 至 " + this.$tools.ZHDate(new Date(item.end_time * 1000)),
                                    chairman: item.chairman,
                                    user_name: item.user_name,
                                    shenpi_no: (type.upper.data.length > poistion ? type.upper.data[poistion].shenpi_no : '') + '</br>' + (type.downstream.data.length > poistion ? type.downstream.data[poistion].shenpi_no : ''),
                                    corporate_name: (type.upper.data.length > poistion ? type.upper.data[poistion].corporate_name : ''),
                                    s_id: (type.upper.data.length > poistion ? type.upper.data[poistion].sid : ''),
                                    p_id: (type.upper.data.length > poistion ? type.upper.data[poistion].pid : ''),
                                    x_id: (type.upper.data.length > poistion ? type.upper.data[poistion].xid : ''),
                                    contacts: (type.upper.data.length > poistion ? type.upper.data[poistion].contacts : ''),
                                    invitation_letter_date: (type.upper.data.length > poistion ? type.upper.data[poistion].invitation_letter_date : ''),
                                    queren_date: (type.upper.data.length > poistion ? type.upper.data[poistion].queren_date : ''),
                                    is_replied: (type.upper.data.length > poistion ? type.upper.data[poistion].is_replied : ''),
                                    //合同金额
                                    amount: (type.upper.data.length > poistion ? type.upper.data[poistion].self_amount_zan : ''),
                                    //服务费
                                    agreement_run_amount: (type.upper.data.length > poistion ? type.upper.data[poistion].agreement_run_amount_self : ''),
//                                  //可用基金
                                    meet_run: (type.upper.data.length > poistion ? type.upper.data[poistion].self_run : ''),
                                    //到账金额
                                    daokuan_amount: (type.upper.data.length > poistion ? type.upper.data[poistion].daokuan_amount_self : ''),
                                    received_date: (type.upper.data.length > poistion ? type.upper.data[poistion].daokuan : ''),
                                    fapiao_amount: '',
                                    invoice_type: (type.upper.data.length > poistion ? type.upper.data[poistion].invoice_type : ''),
                                    ticket_number: '',
                                    invoice_date: (type.upper.data.length > poistion ? type.upper.data[poistion].invoice_date : ''),

                                    d_id: (type.downstream.data.length > poistion ? type.downstream.data[poistion].id : ''),
                                    d_corporate_name: (type.downstream.data.length > poistion ? type.downstream.data[poistion].corporate_name : ''),
                                    payment: (type.downstream.data.length > poistion ? type.downstream.data[poistion].payment : ''),
                                    contract_time: (type.downstream.data.length > poistion ? type.downstream.data[poistion].contract_time : ''),
                                    contract_amount: (type.downstream.data.length > poistion ? type.downstream.data[poistion].contract_amount : ''),
                                    payment_amount: (type.downstream.data.length > poistion ? type.downstream.data[poistion].payment_amount : ''),
                                    received: (type.downstream.data.length > poistion ? type.downstream.data[poistion].received : ''),
                                    collection_amount: (type.downstream.data.length > poistion ? type.downstream.data[poistion].collection_amount : ''),
                                    desc_info: (type.downstream.data.length > poistion ? type.downstream.data[poistion].desc_info : '--'),
                                })
                            }
                            if (type.upper.data.length > 0 || type.downstream.data.length > 0) {
                                _data.push({
                                    column_1: index,
                                    column_2: '2-' + index,
                                    column_3: '0-' + index,
                                    color: item.id === this.oid ? 2 : 1,
                                    name: '可用专项基金合计',
                                    amount: type.upper.hetong_amount_self,
                                    agreement_run_amount: type.upper.society_amount_self,
                                    meet_run: type.upper.can_self_amount,
                                    daokuan_amount: type.upper.daokuan_amount_self,
                                    fapiao_amount: '',
                                    d_corporate_name: '已用专项基金合计',
                                    contract_amount: type.downstream.true_hetong_amount,
                                    payment_amount: type.downstream.fukuan_amount,
                                    collection_amount: type.downstream.shoupiao_amount,
                                })
                            }
                        })
                        this.column_1 = []
                        this.column_2 = []
                        this.column_3 = []
                        this.getSpanArr(_data);
                        this.tableData = _data
                        this.upTableStyle()
                        this.loading = false
                    })
                    .catch(() => {
                        this.tableData = []
                        this.loading = false
                    });
            },
            downFile() {
                this.$api.apiContent
                    .stand_excel({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        start_time:
                            this.start_end_time && this.start_end_time.length > 0
                                ? this.start_end_time[0] / 1000
                                : "",
                        end_time:
                            this.start_end_time && this.start_end_time.length > 0
                                ? ((this.start_end_time[1] / 1000) + (24 * 60 * 60))
                                : "",
                        state: this.state,
                        name: this.search_content,
                        sid: this.sid,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                start_time:
                                    this.start_end_time && this.start_end_time.length > 0
                                        ? this.start_end_time[0] / 1000
                                        : "",
                                end_time:
                                    this.start_end_time && this.start_end_time.length > 0
                                        ? ((this.start_end_time[1] / 1000) + (24 * 60 * 60))
                                        : "",
                                state: this.state,
                                name: this.search_content,
                                sid: this.sid,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        let blob = res.data;
                        var downloadElement = document.createElement('a');
                        var href = window.URL.createObjectURL(blob); //创建下载的链接
                        downloadElement.style.display = 'none';
                        downloadElement.href = href;
                        downloadElement.setAttribute('download', '财务台账' + new Date().getTime() + '.xls');
                        document.body.appendChild(downloadElement);
//                        downloadElement.execCommand("saveAs",true,"name.xls"); //点击下载
                        downloadElement.click(); //点击下载
                        document.body.removeChild(downloadElement); //下载完成移除元素
                        window.URL.revokeObjectURL(href); //释放掉blob对象
                    })
                    .catch((e) => {
                    console.log(e)
                        this.$message.error('下载失败');
                    });
            },
        },
        watch: {
            state() {
                this.search_content = ''
                this.start_end_time = []
            }
        },
        filters: {
            invoice_type(type) {
                switch (Number(type)) {
                    case 1:
                        return "专";
                    case 2:
                        return "普";
                    case 3:
                        return "捐";
                    default:
                        return "";
                }
            },
            format(val) {
                return val ? parseFloat(Number(val).toFixed(6)) : val
            }
        }

    };
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }
        .ticket-container{
            display: flex;
            flex-direction: column;
            .ticket-item{
                display: flex;
                flex-direction: column;
                border: 1px solid #eeeeee;
                border-radius: 5px;
                padding: 16px;
                margin-bottom: 20px;
            }
        }
        .project-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;

            .project-condition {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 24px 28px 16px;
                border-bottom: 2px solid #f5f5f5;
                .search {
                    margin: 0 20px;
                }
                .el-input {
                    margin-left: 20px;
                    width: 268px;
                }
            }

            .project-list {
                padding: 34px 0;
                min-height: 700px;
                width: 97%;
                margin: 0 20px;
                overflow-x: scroll;
                .table-container {
                    width: 97%;
                    .table-header {
                        height: 55px;
                        width: 100%;
                        border: 1px solid #e6e6e6;
                        user-select: none;
                        .header-title {
                            min-width: 160px;
                            border-right: 1px solid #e6e6e6;
                            text-align: center;
                            font-weight: bold;
                            font-size: 16px;
                            &:last-child {
                                border-right: none;
                            }
                            &.sticky {
                                position: sticky;
                                left: 10px;
                            }
                        }
                    }
                    .table-item {
                        height: 55px;
                        width: 100%;
                        border: 1px solid #e6e6e6;
                        cursor: pointer;
                        &:hover {
                            background: #eeeeee;
                        }
                        .item-title {
                            min-width: 150px;
                            border-right: 1px solid #e6e6e6;
                            text-align: center;
                            &:last-child {
                                border-right: none;
                            }
                            &.sticky {
                                position: sticky;
                                left: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
